import React, { useState, useRef } from "react"
import { Link } from "gatsby"
import LOGO from "../../images/logos/SSLLC-Logo_Color_Gradient.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faSearch,
  faChevronDown,
  faChevronUp,
  faPhone,
} from "@fortawesome/free-solid-svg-icons"
import SearchBox from "./searchBox"

// Main Navbar1 Component
const Navbar1 = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const dropdownTimeoutRef = useRef(null)

  const openDropdown = (dropdown) => {
    clearTimeout(dropdownTimeoutRef.current)
    setIsDropdownOpen(dropdown)
  }

  const closeDropdown = () => {
    dropdownTimeoutRef.current = setTimeout(() => {
      setIsDropdownOpen(null)
    }, 300)
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <>
      <header className="">
        <div className="bg-light-gray hidden lg:flex justify-between flex-col">
          <div className="container max-w-screen-xl py-2.5">
            <div className="flex justify-between items-center">
              <div className="flex justify-start w-1/4">
                <Link to="/" aria-label="SSLLC Logo">
                  <img
                    src={LOGO}
                    alt="Surplus Solutions Logo in full color gradient"
                    className="logo-img max-w-[180px]"
                  />
                </Link>
              </div>
              <div className="justify-end w-3/4 items-center space-x-12 flex flex-row">
                {/* Services with Dropdown */}
                <div
                  className="relative group"
                  onMouseEnter={() => openDropdown("services")}
                  onMouseLeave={closeDropdown}
                >
                  <Link
                    className="navLink text-[0.875rem] font-normal"
                    activeClassName="navLink-active"
                    partiallyActive={true}
                    to="/our-services"
                  >
                    Services <FontAwesomeIcon icon={faChevronDown} size="xs" />{" "}
                  </Link>

                  {isDropdownOpen === "services" && (
                    <div className="absolute left-0 mt-8 w-32 bg-white shadow-custom-faq duration-300 transition ease-in-out delay-150 !z-50">
                      <Link
                        to="/our-services/appraisal-services"
                        className="block px-4 py-2 text-charcoal text-[0.875rem] border-b-2 border-b-light-gray hover:text-red hover:bg-gray-100"
                        activeClassName="text-red"
                      >
                        Appraisal
                      </Link>
                      <Link
                        to="/our-services/logistics-services"
                        className="block px-4 py-2 text-charcoal text-[0.875rem] border-b-2 border-b-light-gray hover:text-red hover:bg-gray-100"
                        activeClassName="text-red"
                      >
                        Logistics
                      </Link>
                      <Link
                        to="/our-services/relocation-services"
                        className="block px-4 py-2 text-charcoal text-[0.875rem] border-b-2 border-b-light-gray hover:text-red hover:bg-gray-100"
                        activeClassName="text-red"
                      >
                        Relocation
                      </Link>
                    </div>
                  )}
                </div>

                {/* Learning Center with Dropdown */}
                <div
                  className="relative group"
                  onMouseEnter={() => openDropdown("learning")}
                  onMouseLeave={closeDropdown}
                >
                  <a
                    className="navLink text-[0.875rem] font-normal"
                    // activeClassName="navLink-active"
                    // partiallyActive={true}
                    href="https://connect.ssllc.com/learning-center"
                  >
                    Learning Center{" "}
                    <FontAwesomeIcon icon={faChevronDown} size="xs" />{" "}
                  </a>

                  {isDropdownOpen === "learning" && (
                    <div className="absolute left-0 mt-8 w-36 bg-white shadow-custom-faq duration-300 transition ease-in-out delay-150 !z-50">
                      <a
                        href="https://connect.ssllc.com/learning-center/blog"
                        className="block px-4 py-2 text-charcoal text-[0.875rem] border-b-2 border-b-light-gray hover:text-red hover:bg-gray-100"
                        // activeClassName="text-red"
                      >
                        Blog
                      </a>
                      <Link
                        to="/faq"
                        className="block px-4 py-2 text-charcoal text-[0.875rem] border-b-2 border-b-light-gray hover:text-red hover:bg-gray-100 uppercase"
                        activeClassName="text-red"
                      >
                        FAQ
                      </Link>
                    </div>
                  )}
                </div>

                {/* About with Dropdown */}
                <div
                  className="relative group"
                  onMouseEnter={() => openDropdown("about")}
                  onMouseLeave={closeDropdown}
                >
                  <Link
                    className="navLink text-[0.875rem] font-normal"
                    activeClassName="navLink-active"
                    partiallyActive={true}
                    to="/about-us"
                  >
                    About Us <FontAwesomeIcon icon={faChevronDown} size="xs" />{" "}
                  </Link>

                  {isDropdownOpen === "about" && (
                    <div className="absolute left-0 mt-8 w-32 bg-white shadow-custom-faq duration-300 transition ease-in-out delay-150 !z-50">
                      {/* <Link
                        to="/about-us/our-team"
                        className="block px-4 py-2 text-charcoal text-[0.875rem] border-b-2 border-b-light-gray hover:text-red hover:bg-gray-100"
                        activeClassName="text-red"
                      >
                        Our Team
                      </Link> */}
                      <Link
                        to="/about-us/partners"
                        className="block px-4 py-2 text-charcoal text-[0.875rem] border-b-2 border-b-light-gray hover:text-red hover:bg-gray-100"
                        activeClassName="text-red"
                      >
                        Partners
                      </Link>
                      <a
                        href="https://connect.ssllc.com/learning-center/news"
                        className="block px-4 py-2 text-charcoal text-[0.875rem] border-b-2 border-b-light-gray hover:text-red hover:bg-gray-100"
                        // activeClassName="text-red"
                      >
                        News
                      </a>
                      <a
                        href="https://connect.ssllc.com/learning-center/conferences"
                        className="block px-4 py-2 text-charcoal text-[0.875rem] border-b-2 border-b-light-gray hover:text-red hover:bg-gray-100"
                        // activeClassName="text-red"
                      >
                        Conferences
                      </a>
                    </div>
                  )}
                </div>

                <Link
                  to="/contact-us"
                  className=" bg-red hover:bg-scarlet-red py-2 px-4 text-white hover:text-white rounded-[4px] transition duration-300 text-[0.875rem]"
                >
                  Contact Us
                </Link>
                <a
                  className="text-[0.875rem] text-charcoal"
                  href="tel:+14015260055"
                >
                  401-526-0055 &nbsp;
                  <FontAwesomeIcon
                    icon={faPhone}
                    className="text-red"
                    transform={{ flipX: true }}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-row bg-white w-full shadow-custom-faq !z-40">
            <div className="container max-w-screen-xl py-5">
              <div className="flex justify-between items-center">
                <div className="flex space-x-12">
                  <Link
                    className="navLink text-[1.125rem]"
                    activeClassName="navLink-active"
                    partiallyActive={true}
                    to="/catalog/new-arrivals"
                  >
                    New Arrivals
                  </Link>

                  {/* Buy Equipment with Dropdown */}
                  <div
                    className="relative group"
                    onMouseEnter={() => openDropdown("buy-equipment")}
                    onMouseLeave={closeDropdown}
                  >
                    <Link
                      className="navLink text-[1.125rem]"
                      activeClassName="navLink-active"
                      partiallyActive={true}
                      to="/buy-equipment"
                    >
                      Buy Equipment{" "}
                      <FontAwesomeIcon icon={faChevronDown} size="xs" />{" "}
                    </Link>
                    {isDropdownOpen === "buy-equipment" && (
                      <div className="absolute left-0 mt-6 w-64 bg-white shadow-custom-faq duration-300 transition ease-in-out delay-150 !z-50">
                        <Link
                          to="/buy-equipment/manufacturers"
                          className="block px-4 py-2 text-charcoal hover:bg-light-gray text-base border-b-2 border-b-light-gray"
                          activeClassName="text-red"
                        >
                          Manufacturers
                        </Link>
                        <Link
                          to="/buy-equipment/lab-equipment"
                          className="block px-4 py-2 text-charcoal hover:bg-light-gray text-base border-b-2 border-b-light-gray"
                          activeClassName="text-red"
                        >
                          Lab Equipment
                        </Link>
                        <Link
                          to="/buy-equipment/processing-equipment"
                          className="block px-4 py-2 text-charcoal hover:bg-light-gray text-base border-b-2 border-b-light-gray"
                          activeClassName="text-red"
                        >
                          Processing Equipment
                        </Link>
                        <Link
                          to="/buy-equipment/packaging-equipment"
                          className="block px-4 py-2 text-charcoal hover:bg-light-gray text-base border-b-2 border-b-light-gray"
                          activeClassName="text-red"
                        >
                          Packaging Equipment
                        </Link>
                        {/* <Link
                          to="/buy-equipment/facilities-office-equipment"
                          className="block px-4 py-2 text-charcoal hover:bg-light-gray text-base border-b-2 border-b-light-gray"
                          activeClassName="text-red"
                        >
                          Facilities & Office Equipment
                        </Link> */}
                        <Link
                          to="/buy-equipment/medical-equipment"
                          className="block px-4 py-2 text-charcoal hover:bg-light-gray text-base border-b-2 border-b-light-gray"
                          activeClassName="text-red"
                        >
                          Medical Equipment
                        </Link>
                        <Link
                          to="/catalog/refurbished"
                          className="block px-4 py-2 text-charcoal hover:bg-light-gray text-base border-b-2 border-b-light-gray"
                          activeClassName="text-red"
                        >
                          Refurbished Equipment
                        </Link>
                      </div>
                    )}
                  </div>

                  <Link
                    to="/auctions"
                    className="navLink text-[1.125rem]"
                    activeClassName="navLink-active"
                    partiallyActive={true}
                  >
                    Auctions
                  </Link>
                  <Link
                    to="/sell-equipment"
                    className="navLink text-[1.125rem]"
                    activeClassName="navLink-active"
                    partiallyActive={true}
                  >
                    Sell Equipment
                  </Link>
                </div>
                {/* Search Bar */}
                <div className="w-2/5">
                  <SearchBox />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile Menu */}
        <div className="bg-white lg:hidden relative shadow-custom-faq z-40">
          <div className="flex justify-between items-center px-10 py-2.5 bg-white">
            <Link
              to="/"
              aria-label="SSLLC Logo"
              onClick={toggleMenu}
              className="flex-shrink-0"
            >
              <img
                src={LOGO}
                alt="Surplus Solutions Logo in full color gradient"
                className=" logo-img max-w-[180px]"
              />
            </Link>
            <div className="lg:hidden flex items-center py-4  ">
              <a
                className="text-base text-charcoal pr-5"
                href="tel:+14015260055"
              >
                <FontAwesomeIcon
                  icon={faPhone}
                  className="text-red"
                  transform={{ flipX: true }}
                />
              </a>

              <button
                onClick={toggleMenu}
                aria-label="Toggle menu"
                className="text-gray-600"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d={
                      isMenuOpen
                        ? "M6 18L18 6M6 6l12 12"
                        : "M4 6h16M4 12h16M4 18h16"
                    }
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* Render Mobile Menu */}
        <MobileMenu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
      </header>
    </>
  )
}

const MobileMenu = ({ isMenuOpen, toggleMenu }) => {
  const [openAccordion, setOpenAccordion] = useState(null)

  const toggleAccordion = (section) => {
    setOpenAccordion(openAccordion === section ? null : section)
  }

  return (
    <>
      {isMenuOpen && (
        <div className="fixed inset-0 !z-50 lg:hidden" onClick={toggleMenu}>
          <div
            className="fixed right-0 top-0 w-full h-full z-40 flex flex-col overflow-y-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center px-10 py-2.5 bg-white shadow-custom-faq">
              <Link
                to="/"
                aria-label="SSLLC Logo"
                onClick={toggleMenu}
                className="flex-shrink-0"
              >
                <img
                  src={LOGO}
                  alt="Surplus Solutions Logo in full color gradient"
                  className=" logo-img max-w-[180px]"
                />
              </Link>
              <div className="flex items-center py-4">
                <a
                  className="text-base text-charcoal pr-5"
                  href="tel:+14015260055"
                >
                  <FontAwesomeIcon
                    icon={faPhone}
                    className="text-red"
                    transform={{ flipX: true }}
                  />
                </a>

                <button
                  onClick={toggleMenu}
                  aria-label="Close menu"
                  className="text-gray-600"
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>

            {/* Search bar */}
            <div className="relative px-7 py-5 text-lg border-b-2 border-light-gray bg-white mt-1">
              <SearchBox />
            </div>

            {/* Menu Links */}
            <div className="flex flex-col bg-white h-fit">
              <Link
                to="/catalog/new-arrivals"
                className="block px-7 py-5 text-charcoal hover:bg-light-gray border-b-2 border-b-light-gray text-lg"
                onClick={toggleMenu}
              >
                New Arrivals
              </Link>

              {/* Buy Equipment with Accordion */}

              <div
                className={`block items-center text-charcoal hover:bg-light-gray border-b-2 border-b-light-gray text-lg ${
                  openAccordion === "buy-equipment" ? "bg-light-gray" : ""
                }`}
              >
                <button
                  className={`flex justify-between w-full px-7 py-5 ${
                    openAccordion === "buy-equipment" ? "bg-light-gray" : ""
                  }`}
                  onClick={() => toggleAccordion("buy-equipment")}
                >
                  <Link
                    to="/buy-equipment"
                    className="text-charcoal hover:text-charcoal "
                    onClick={toggleMenu}
                  >
                    Buy Equipment
                  </Link>
                  <FontAwesomeIcon
                    icon={
                      openAccordion === "buy-equipment"
                        ? faChevronUp
                        : faChevronDown
                    }
                  />
                </button>
              </div>
              {openAccordion === "buy-equipment" && (
                <div className="">
                  {/* "Facilities & Office Equipment" */}
                  <Link
                    to="/buy-equipment/manufacturers"
                    className="block  px-10 py-5 text-charcoal hover:bg-light-gray border-b-2 border-b-light-gray text-lg"
                    onClick={toggleMenu}
                  >
                    Manufacturers
                  </Link>
                  <Link
                    to="/buy-equipment/lab-equipment"
                    className="block  px-10 py-5 text-charcoal hover:bg-light-gray border-b-2 border-b-light-gray text-lg"
                    onClick={toggleMenu}
                  >
                    Lab Equipment
                  </Link>
                  <Link
                    to="/buy-equipment/processing-equipment"
                    className="block  px-10 py-5 text-charcoal hover:bg-light-gray border-b-2 border-b-light-gray text-lg"
                    onClick={toggleMenu}
                  >
                    Processing Equipment
                  </Link>
                  <Link
                    to="/buy-equipment/packaging-equipment"
                    className="block  px-10 py-5 text-charcoal hover:bg-light-gray border-b-2 border-b-light-gray text-lg"
                    onClick={toggleMenu}
                  >
                    Packaging Equipment
                  </Link>
                  <Link
                    to="/buy-equipment/medical-equipment"
                    className="block  px-10 py-5 text-charcoal hover:bg-light-gray border-b-2 border-b-light-gray text-lg"
                    onClick={toggleMenu}
                  >
                    Medical Equipment
                  </Link>
                  <Link
                    to="/catalog/refurbished"
                    className="block  px-10 py-5 text-charcoal hover:bg-light-gray border-b-2 border-b-light-gray text-lg"
                    onClick={toggleMenu}
                  >
                    Refurbished Equipment
                  </Link>
                </div>
              )}

              <Link
                to="/auctions"
                className="block px-7 py-5 text-charcoal hover:bg-light-gray border-b-2 border-b-light-gray text-lg"
                onClick={toggleMenu}
              >
                Auctions
              </Link>
              <Link
                to="/sell-equipment"
                className="block px-7 py-5 text-charcoal hover:bg-light-gray border-b-2 border-b-light-gray text-lg"
                onClick={toggleMenu}
              >
                Sell Equipment
              </Link>

              {/* Our Services with Accordion */}
              <div
                className={`block items-center text-charcoal hover:bg-light-gray border-b-2 border-b-light-gray text-lg ${
                  openAccordion === "our-services" ? "bg-light-gray" : ""
                }`}
              >
                <button
                  className={`flex justify-between w-full px-7 py-5 ${
                    openAccordion === "our-services" ? "bg-light-gray" : ""
                  }`}
                  onClick={() => toggleAccordion("our-services")}
                >
                  <Link
                    to="/our-services"
                    className="text-charcoal hover:text-charcoal "
                    onClick={toggleMenu}
                  >
                    Our Services
                  </Link>
                  <FontAwesomeIcon
                    icon={
                      openAccordion === "our-services"
                        ? faChevronUp
                        : faChevronDown
                    }
                  />
                </button>
              </div>
              {openAccordion === "our-services" && (
                <div className=" space-y-2">
                  {[
                    "Appraisal Services",
                    "Logistics Services",
                    "Relocation Services",
                  ].map((service) => (
                    <Link
                      to={`/our-services/${service
                        .toLowerCase()
                        .replace(/ /g, "-")}`}
                      key={service}
                      className="block  px-10 py-5 text-charcoal hover:bg-light-gray border-b-2 border-b-light-gray text-lg"
                      onClick={toggleMenu}
                    >
                      {service}
                    </Link>
                  ))}
                </div>
              )}

              {/* Learning Center with Accordion */}
              <div
                className={`block items-center text-charcoal hover:bg-light-gray border-b-2 border-b-light-gray text-lg ${
                  openAccordion === "learning-center" ? "bg-light-gray" : ""
                }`}
              >
                <button
                  className={`flex justify-between w-full px-7 py-5 ${
                    openAccordion === "learning-center" ? "bg-light-gray" : ""
                  }`}
                  onClick={() => toggleAccordion("learning-center")}
                >
                  <a
                    href="https://connect.ssllc.com/learning-center"
                    className="text-charcoal hover:text-charcoal "
                    onClick={toggleMenu}
                  >
                    Learning Center
                  </a>
                  <FontAwesomeIcon
                    icon={
                      openAccordion === "learning-center"
                        ? faChevronUp
                        : faChevronDown
                    }
                  />
                </button>
              </div>
              {openAccordion === "learning-center" && (
                <div className="space-y-2">
                  <a
                    href="https://connect.ssllc.com/learning-center/blog"
                    className="block px-10 py-5 text-charcoal hover:bg-light-gray border-b-2 border-b-light-gray text-lg"
                    onClick={toggleMenu}
                  >
                    Blog
                  </a>
                  <Link
                    to="/faq"
                    className="block px-10 py-5 text-charcoal border-b-2 border-b-light-gray hover:bg-light-gray text-lg uppercase"
                    onClick={toggleMenu}
                  >
                    FAQ
                  </Link>
                </div>
              )}

              {/* About Us with Accordion */}
              <div
                className={`block items-center text-charcoal hover:bg-light-gray border-b-2 border-b-light-gray text-lg ${
                  openAccordion === "about-us" ? "bg-light-gray" : ""
                }`}
              >
                <button
                  className={`flex justify-between w-full px-7 py-5 ${
                    openAccordion === "about-us" ? "bg-light-gray" : ""
                  }`}
                  onClick={() => toggleAccordion("about-us")}
                >
                  <Link
                    to="/about-us"
                    className="text-charcoal hover:text-charcoal "
                    onClick={toggleMenu}
                  >
                    About Us
                  </Link>
                  <FontAwesomeIcon
                    icon={
                      openAccordion === "about-us" ? faChevronUp : faChevronDown
                    }
                  />
                </button>
              </div>
              {openAccordion === "about-us" && (
                <div className="space-y-2">
                  {/* @TODO: add "Our Team" in phase 2 */}
                  {/* {["Partners", "News", "Conferences"].map((section) => (
                    <Link
                      to={`/about-us/${section
                        .toLowerCase()
                        .replace(/ /g, "-")}`}
                      key={section}
                      className="block px-10 py-5 text-charcoal hover:bg-light-gray border-b-2 border-b-light-gray text-lg"
                      onClick={toggleMenu}
                    >
                      {section}
                    </Link>
                  ))} */}
                  <Link
                    to="/about-us/partners"
                    className="block px-10 py-5 text-charcoal border-b-2 border-b-light-gray hover:bg-light-gray text-lg"
                    onClick={toggleMenu}
                  >
                    Partners
                  </Link>
                  <a
                    href="https://connect.ssllc.com/learning-center/news"
                    className="block px-10 py-5 text-charcoal hover:bg-light-gray border-b-2 border-b-light-gray text-lg"
                    onClick={toggleMenu}
                  >
                    News
                  </a>
                  <a
                    href="https://connect.ssllc.com/learning-center/conferences"
                    className="block px-10 py-5 text-charcoal hover:bg-light-gray border-b-2 border-b-light-gray text-lg"
                    onClick={toggleMenu}
                  >
                    Conferences
                  </a>
                </div>
              )}

              <Link
                to="/contact-us"
                className="block px-7 py-5 text-charcoal hover:bg-light-gray border-b-2 border-b-light-gray text-lg"
                onClick={toggleMenu}
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default Navbar1
